.campaign-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.field-with-hint {
  display: flex;
  align-items: flex-end;
  max-width: 100%;
  min-height: 0;

  p {
    padding-left: 30px;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.non-scrollable {
  overflow: hidden;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  padding: 35px;
  width: 100%;
  height: 100%;
}

.jumbotron {
  background-color: $text-color-light-bg;
  color: $text-color-light;
  padding: 30px;
  width: 100%;
  text-align: center;
}
