@import "components/tabs";

.frame-card {
  @include frame-shadow();
  max-width: 750px;
  width: 100%;
  padding: 40px 20px;
  max-height: calc(100% - 60px);
}

