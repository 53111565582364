
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

.rich-text {
    font-family: 'Open sans', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #221F20;
    text-align: left;
    /*--theme-color: #FF00FF*/
    --theme-color: #d62f2f
}

.rich-text > h1 {
    display: block;
    font-size: 26px;
    line-height: 42px;
    color: #221F20;
    opacity: 0.9;
    font-weight: bold;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
}

.rich-text > h2 {
    font-size: 20px;
    color: #221F20;
    opacity: 0.9;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 40px;
}

.rich-text > h3 {
  font-size: 18px;
  color: #221F20;
  opacity: 0.9;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 40px;
}

.rich-text > p {
  font-size: 14px;
  line-height: 17px;
  color: #221F20;
  margin-bottom: 20px;
  opacity: 0.9;
}

.rich-text a {
    font-size: 14px;
    line-height: 21px;
    color: #221F20 !important;
    text-decoration: underline
}

.rich-text > figure {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    text-align: center;
}

.rich-text > figure > img {
    max-width: 100%;
}

.align-center {
  text-align: center !important; }

.align-left {
  text-align: left !important; }

.align-right {
  text-align: right !important; }

.align-justify {
  text-align: justify !important; }

figure.outset-left {
  text-align: left !important;
}

figure.outset-right {
  text-align: right !important;
}

figure {
  text-align: center;
}
