.ReactVirtualized__Grid:focus,
.ReactVirtualized__Table__row:focus,
.ReactVirtualized__Table__sortableHeaderColumn:focus {
  outline: none;
}

.ReactVirtualized__Table__headerRow,
.ReactVirtualized__Table__row {
  display: flex;
}

.ReactVirtualized__Table__sortableHeaderIcon {
  width: 1em;
  height: 1em;
}

.sortable-table-bg .ReactVirtualized__Grid__innerScrollContainer {
  background: #dfe0e2;
}

.sortable-table-moving {
  box-shadow: -2px -2px 5px 0 rgba(0, 0, 0, 0.4);
  background: #595959 !important;
  color: white !important;
}

.sortable-table-moving .on-hover.drag-handle {
  display: flex !important;
}

.ReactVirtualized__Table__Grid {
  overflow: visible !important;
}

.ReactVirtualized__Table__headerColumn {
  display: flex;
  flex-direction: column-reverse;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
