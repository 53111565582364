.pie-chart-master-label {
  font-family: 'Roboto';
  color: #464d54;
  font-weight: bold;
}

.pie-chart-sector-label {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 12px;
}

.pie-chart-sector-stat {
  font-family: 'Roboto';
  font-size: 12px;
}
