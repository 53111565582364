.donor-detail {
  .additional-field {
    display: flex;
    margin-right: 23px;
    border-left: 3px solid rgb(244, 244, 244);
    padding: 5px 0 5px 23px;
    flex-wrap: wrap;
    font-size: 16px;

    .label {
      color: #a2a2a2;
      width: auto;
      white-space: normal;
      display: block;
      padding-right: 4px;
    }
    .value {
      color: #626262;
      word-break: break-all;
      display: block;
    }
  }
}
