.tabs {
  .switch-panel {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: stretch;
  }

  .tab {
    padding: 10px 50px 10px 0;
    border-bottom: 5px solid $text-color-light;
    flex: 1 1 0;

    &.active {
      button {
        color: $color-primary;
      }
      border-bottom-color: $color-primary;
    }

    button {
      background: none;
      font-weight: bold;
      font-size: 31px;
      color: $text-color-light;
      cursor: pointer;
      text-transform: uppercase;
      text-align: left;
      width: 100%;
    }
  }

  &.condensed {
    .tab {
      padding-left: 20px;
    }
  }

  &.small {
    .tab {
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom-width: 4px;

      button {
        font-size: 18px;
        font-weight: normal;
      }
    }
  }

  .tab-content {
    display: none;

    &.active {
      display: initial;
    }
  }
}
