.DayPicker {
  font-family: Roboto, sans-serif;
  color: #595959;
  overflow: hidden;
}

.CalendarDay__default:hover {
  border: none;
}

.DayPicker__withBorder {
  border-radius: 5px;
  box-shadow: 0 0 10px 2px rgba(231, 231, 231, 1);
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #909090;
  color: #fff;
}

.CalendarDay__default:hover {
  background: #6c6c6c;
  color: #fff;
}

.CalendarDay__hovered_span {
  background: #eee;
  color: #595959;
}

.CalendarDay__selected_span {
  color: #595959;
  background: #eee;
}

.DayPickerNavigation_button__horizontalDefault {
  top: 23px;
}

.DayPickerNavigation_leftButton__horizontalDefault {
  left: 31px;
}

.DayPickerNavigation_rightButton__horizontalDefault {
  right: 33px;
}

.DayPicker_weekHeader_li {
  cursor: default;
}

.DayPickerNavigation_button__default:focus, .DayPickerNavigation_button__default:hover {
  border: none;
}
