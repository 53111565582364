.campaign {
  display: flex;
  flex-direction: column;

  .step-header {
    display: flex;
    padding: 10px 0;
    align-items: center;
    .step-number {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: white;
      background-color: $color-primary;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-weight: bold;
      margin-right: 5px;
    }
    .step-title {
      font-weight: bold;
      color: $text-color-dark;
      font-size: 13px;
    }
  }

  .matching-logo-preview {
    padding-left: 20px;
    max-height: 80px;
    object-fit: contain;
  }
}
