table.react-table {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  border-collapse: collapse;
}

table.react-table th, td {
  padding-left: 14px;
  padding-right: 14px;
  text-align: left;
  height: 50px;
  font-weight: normal;
}

table.react-table tr:nth-child(odd) {
  background-color: #f4f4f4;
}

table.react-table th {
  color: #586470;
  background-color: white !important;
  height: 60px;
  padding: 0;
}

table.react-table th .table-header {
  height: 53px;
  border-bottom: 3px solid #f4f4f4;
  margin-bottom: 7px;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 50px;
}

table.react-table td {
  color: #666666;
}

table.react-table tr .clickable {
  cursor: pointer
}

table.react-table.padded td, th {
  padding: 12px;
}
