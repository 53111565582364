.mobile-page {
  height: 100vh;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.mobile-page .content {
  padding: 30px 15px;
}

.mobile-page .content .section-label {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;
}

.mobile-page .content .button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.mobile-header {
  position: static;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px 0 30px;
  color: #2c2f33;
}

.mobile-header .mobile-logo {
  transform: scale(0.8);
}

.mobile-header > h1 {
  display: inline;
  font-size: 20px;
}

button.menu-button {
  background: transparent;
  font-size: 20px;
  color: #2c2f33;
  cursor: pointer;
}

button.menu-button.close {
  padding-right: 10px;
}

.mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f4f4f4;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.mobile-menu .menu-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px 30px;
}

.menu-content ul.menu {
  list-style: none;
  padding-left: 0;
}

.menu-content ul.menu li a {
  font-size: 22px;
  color: #2c2f33;
  line-height: 50px;
  font-weight: bold;
}

.menu-content ul.menu li a.active {
  color: #d62f2f;
}

.mobile-page .summary {
  border-radius: 10px;
  background-color: #f8f8f8;
}

.mobile-page .logo-button {
  background: transparent;
  cursor: pointer;
}

.mobile-page .summary .summary-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  flex-wrap: wrap;
}

.mt-1 {
  margin-top: 8px;
}

.mr-1 {
  margin-right: 12px;
}

table.mobile-table {
  width: 100%;
  border-collapse: collapse;
}

table.mobile-table td, table.mobile-table th {
  font-size: 13px;
  line-height: 17px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  color: #2c2f33;
}

table.mobile-table td:first-child, table.mobile-table th:first-child {
  padding-left: 0
}

table.mobile-table td:last-child, table.mobile-table th:last-child {
  padding-right: 0
}

table.mobile-table th {
  font-weight: normal;
  padding-bottom: 10px;
}

table.mobile-table td {
  border-top: 1px solid #efefef;
}

table.mobile-table th {
  border-bottom: 2px solid #efefef;
}

.align-right {
  text-align: right !important;
}

table.mobile-table td .summary-cell {
  display: flex;
  align-items: center;
}

table.mobile-table td .donated-cell {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

table.mobile-table td .donated-cell .donations-summary {
  display: flex;
  flex-direction: row;
}

table.mobile-table td .pale-text {
  color: #848484;
}

table.mobile-table .table-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

table.mobile-table th .head-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

table.mobile-table th .head-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}


.search-bar-wrapper {
  margin-bottom: 20px;
}

.no-data {
  font-size: 16px;
  font-weight: bold;
  color: #a0a0a0;
  width: 100%;
  padding: 20px 0;
  text-align: center;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.overflow-ellipsis-wrap {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.clickable {
  cursor: pointer
}
