.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-20 {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}
